import React, { useEffect, useRef, useState } from 'react';

import b from '../img/sb/b.png'; // Adjust the path accordingly
import c from '../img/sb/c.png'; // Adjust the path accordingly
import d from '../img/sb/d.png'; // Adjust the path accordingly
import e from '../img/sb/e.png'; // Adjust the path accordingly
import f from '../img/sb/f.png'; // Adjust the path accordingly
import './index.css';
const Imges = () => {
  const scrollRef = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);

  const images = [ b, c, d, e, f]; // Replace with your actual images

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let scrollLeft = scrollContainer.scrollLeft;

    const interval = setInterval(() => {
      if (!autoScroll) {
        return;
      }

      scrollLeft += 1;
      scrollContainer.scrollLeft = scrollLeft;

      if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
        scrollLeft = 0;
      }
    }, 10); // 16ms for smoother animation

    const handleMouseDown = () => {
      setAutoScroll(false);
      scrollLeft = scrollContainer.scrollLeft;
    };

    const handleMouseUp = () => setAutoScroll(true);
    const handleMouseLeave = () => setAutoScroll(true);

    scrollContainer.addEventListener('mousedown', handleMouseDown);
    scrollContainer.addEventListener('mouseup', handleMouseUp);
    scrollContainer.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      clearInterval(interval);
      scrollContainer.removeEventListener('mousedown', handleMouseDown);
      scrollContainer.removeEventListener('mouseup', handleMouseUp);
      scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [autoScroll]);

  return (
    <div className="   md:p-10">
      <div
        ref={scrollRef}
        className=" hide-scrollbar flex rounded-2xl  border-4 overflow-x-scroll"
      >
        {images.map((img, index) => (
          <img
            key={index}
            className="object-contain h-48 w-48 mx-1"
            alt="Sample"
            src={img}
          />
        ))}
        {/* Duplicate set of images */}
        {images.map((img, index) => (
          <img
            key={index + images.length}
            className="object-contain h-48 w-48 mx-1"
            alt="Sample"
            src={img}
          />
        ))}
      </div>
    </div>
  );
};

export default Imges;
