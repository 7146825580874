import React from 'react';

const Policy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
        <p className="text-lg">
          Welcome to our site. This Privacy Policy is designed to inform you
          about how we collect, use, and protect your personal information. We
          value your privacy and are committed to safeguarding your data. This
          document outlines our policies and practices in detail, so please read
          it carefully.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
        <p className="text-lg">
          We may collect various types of information, including but not limited
          to your name, email address, phone number, and other identifying
          information. We also collect data about your usage of our services,
          such as the pages you visit, the time you spend on each page, and the
          actions you take. This helps us improve our services and provide a
          better user experience.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">How We Use Information</h2>
        <p className="text-lg">
          We use the information we collect for various purposes, including
          providing our services, improving our offerings, and communicating
          with you. We may also use your information for marketing purposes,
          such as sending you promotional materials and updates about new
          features or products. We may also use your information to comply with
          legal obligations or in the course of resolving disputes or enforcing
          our agreements.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Third-Party Services</h2>
        <p className="text-lg">
          We may share your information with third-party services to help us
          provide our services, for marketing purposes, and for other business
          activities. These third parties are carefully selected and are
          obligated to keep your data secure. However, we are not responsible
          for the privacy practices of these third-party services.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">
          Responsibility for External Payments
        </h2>
        <p className="text-lg">
          We provide information and links to external websites and services,
          but we are not responsible for any payments or transactions you make
          with other companies or websites.
        </p>
        <p className="text-lg">
          As a user, you have full responsibility for any financial transactions
          and agreements you enter into with external websites or companies that
          you access through our site. We are solely providing you with
          information and do not act as intermediaries in these transactions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Payment</h2>
        <p className="text-lg">
          We take payment to provide you with more information. We use secure
          payment gateways to process transactions and do not store your
          financial information.
        </p>
        <p className="text-lg">
          please be aware that all sales are final. Once a payment is made,if we
          decide to close the site or you discontinue using our services you do
          not have any right to request a refund. if you made the stripe payment
          you will automaticly be redircted to our links page and than you do not
          have any right to request a refund.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Security</h2>
        <p className="text-lg">
          We take the security of your information seriously and implement
          various security measures, including encryption and authentication
          tools, to protect your information from unauthorized access. Despite
          these measures, we cannot guarantee the absolute security of your
          information.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Changes to This Policy</h2>
        <p className="text-lg">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any significant changes by posting the
          new Privacy Policy on this page and updating the "Last Updated" date
          at the bottom of this document.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
        <p className="text-lg">
          If you have any questions, concerns, or comments about this Privacy
          Policy, please feel free to contact us at{' '}
          <h1 className="text-blue-800">liqudationpallet@gmail.com</h1>
          We value your feedback and will respond to your queries as soon as
          possible.
        </p>
      </section>
    </div>
  );
};

export default Policy;
