import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hero from './component/hero/hero';

import Navbar from './component/navbar/navbar';

import Imges from './imgesS/imges';
import Content from './component/content/content';
import Ecommerce from './component/ecommerce/ecommerce';
import Footer from './component/footer/footer';

import Policy from './component/policy/policy';
import Swish from './pages/swish/swish';
import "../src/App.css"
import ContactUs from './pages/contact/contact';
import Products from "./component/products/products";
import Envairoment from "./component/envairoment/envairoment";

function App() {
  return (
    <Router>
      <div className="overflow-hidden flex-wrap h-full bg-slate-100 ">
        <Navbar />

        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/policy" element={<Policy />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/swish" element={<Swish />} />

          {/* Add more Routes here */}
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

// HomePage component encapsulating existing components
function HomePage() {
  return (
    <>
    <div className="bg-gradient-radial">
      <Hero />
      </div>



      <Products/>
      <Imges />


      <Content />




    </>
  );
}

export default App;
