import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import IconButton from '@mui/material/IconButton';
import E from '../../img/P/e.png';
import B from '../../img/P/b.png';
import C from '../../img/P/c.png';
import D from '../../img/P/d.png';
import F from '../../img/P/f.png';
import G from '../../img/P/g.png';
import H from '../../img/P/h.png';
import I from '../../img/P/i.png';
import J from '../../img/P/j.png';
import K from '../../img/P/k.png';

export default function Products() {
  return (
    <div className="p-2 border-t-[5px]  flex-row border-red-50  ">
      <div className="p-2 w-30 px-5">
        <span className=" rounded-xl font-bold  border-4 border-green-200 shadow-lg shadow-black  justify-center">
          Brett sortiment av produkter.{' '}
          <span className="text-green-500">Oslagbara priser</span>
        </span>
        <br />

          <p className="pt-5 border-b-[2px]  border-emerald-50"> köp i stora kvantiteter eller bara en enstaka artikel</p>


      </div>
      <ImageList
        sx={{
          width: '100%',
          height: 350,
          borderRadius: '26px',
          padding: '14px',
          backgroundColor: '#ffffff',
          margin: '2px',
        }}
      >
        <ImageListItem key="Subheader" cols={2}></ImageListItem>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

const itemData = [
  {
    img: E,

    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: B,
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: C,
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img:D,
    title: 'Coffee',
    author: '@nolanissac',
    cols: 2,
  },
  {
    img: F,
    title: 'Hats',
    author: '@hjrc33',
    cols: 2,
  },
  {
    img: G,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: H,
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: I,
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: J,
    title: 'Mushrooms',
    author: '@silverdalex',
    rows: 2,
    cols: 2,
  },
  {
    img: K,
    title: 'Tomato basil',
    author: '@shelleypauls',
  },

];
