import React, { useState, useEffect } from 'react';
import A from '../../img/pallar.png'; // Import the image
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Button, Divider } from '@mui/material';
import axios from 'axios';

import { Link } from 'react-router-dom';

const Hero = () => {
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [showRedLight, setShowRedLight] = useState(false);

  const handleStripePayment = () => {
    if (privacyChecked) {
      window.location.href = 'https://buy.stripe.com/9AQ17B5ml5703XW4gg';
    } else {
      setShowRedLight(true);
    }
  };

  // Toggle payment form visibility
  const handleButtonClick = () => {
    setShowPaymentForm(!showPaymentForm);
  };

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= 768;

  return (
    <div>
      <div className="container  mx-auto flex flex-col-reverse px-5 py-4 shad m-2 md:flex-row  items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            <span className="text-black "> Någon annans </span>
            <span className="text-red-600 ">Förlust</span>
            <span className="text-black">
              {' '}
              <br /> kan vara din{' '}
            </span>
            <span className="text-green-600">Vinst</span>
            <span className="hidden lg:inline-block">
              <br />
            </span>
          </h1>
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            {/* ... */}
          </h1>
          <p className="mb-8 leading-relaxed">
          Bra deals på returnerade varor
          </p>

          {/* Payment Buttons */}
          <div className="flex justify-center">
            {!showPaymentForm ? (
              <button
                onClick={handleButtonClick}
                className="border-2 border-green-400 inline-flex shadow-xl shadow-green-200 text-white bg-green-500 py-1 px-3 focus:outline-none hover:bg-green-600 rounded-2xl text-md"
              >
                Handla
              </button>
            ) : (
              <div className="bg-white border-2 border-slate-200 rounded-xl p-2 shadow-lg">
                <div className="text-center p-10 py-0 h-auto">
                  <h1 className="m-2 p-2 py-0">
                  Handla smart och maximera värdet av dina inköp
                  </h1>
                  <p className="  text-zinc-500 text-xs">
                    {' '}
                    betalning är skyddade av Stripe
                  </p>

                  <Divider />
                  <div className="m-2 p-2">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => setPrivacyChecked(!privacyChecked)}
                      />
                      I agree to Liqudation Pallet{' '}
                      <Link className="text-blue-500" to="/policy">
                        Policy
                      </Link>
                    </label>
                    {showRedLight && !privacyChecked && (
                      <div className="text-red-600 text-xs">
                        You must agree to the site Policy.
                      </div>
                    )}
                  </div>
                  <div className="flex justify-center items-center">
                    <div className="flex gap-10">
                      <button
                        onClick={handleStripePayment}
                        className="bg-orange-500 rounded-xl border-2 text-center focus:outline-none w-40"
                      >
                        <h1 className="">Fortsätt</h1>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Image */}
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover bg-gradient-radial ellipse"
            alt="Amazon returns"
            src={A}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
