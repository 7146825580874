import React from 'react';
import Logo from '../../img/Logo.png'; // Adjust the path accordingly
import { Link } from 'react-router-dom';
const Navbar = () => {
  return (
    <div>
      <header className="text-blue-600 body-font">
        <div className="container mx-auto flex flex-wrap p-2 flex-col md:flex-row items-center">
          <a className="flex  title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <Link to="/">
          <img
                  className=" object-contain w-8"
                  alt="Amazon returns"
                  src={Logo} // Use the imported image here
                /></Link>
            <span className="ml-0 p-1 text-sm">Liqudation Pallet</span>

          </a>
          <nav>


      {/* More navigation items */}
    </nav>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
