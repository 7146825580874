import React from 'react';
import Logo from '../../img/Logo.png'; // Adjust the path accordingly
import Swish from '../../img/Swish Logo Primary PNG.png';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div>
      <footer className="text-gray-600    bg-black body-font">
        <div className="container px-2 py-4 mx-auto flex items-center sm:flex-row flex-col">
          <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img
              className=" object-contain bg-slate-400 w-[25px]  rounded-xl "
              alt="Amazon returns"
              src={Logo} // Use the imported image here
            />
            <span className="  text-slate-400 text-xs p-2">
              Liqudation Pallet
            </span>
          </a>
          <p className="text-xs text-gray-500 sm:ml-4  sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
            ©2022{' '}
            <GppGoodIcon sx={{ width: '20px', height: 20 }} />
          </p>
          <span className="flex sm:ml-auto  justify-center sm:justify-start text-center ">
            <Link className="text-gray-500 text-xs p-2 m-2" to="/contactus">
              Kontakta Oss
            </Link>

            <a class="">
              <Link to="/swish" className="ml-3 p-4 text-gray-500 flex">
                <img
                  className="w-[15px]  object-contain"
                  alt="Amazon returns"
                  src={Swish} // Use the imported image here
                />
              </Link>
            </a>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
