import React from 'react'
import S from '../../img/Swish Logo Primary PNG.png';
const Swish = () => {
  return (
    <div>
    <img
    className="object-cover  "
    alt="lager"
    src={S} // Use the imported image here
  />
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mx-auto mt-8">


    <h2 className="text-2xl font-semibold mb-4 ">Betalningsinformation</h2>
    <p className="text-base text-gray-700">
      Vi arbetar för närvarande på att kunna erbjuda betalningar via Swish på vår hemsida.
      Snart kommer du att kunna genomföra dina köp ännu smidigare. Tack för ditt tålamod!
    </p>
  </div>
  </div>
  )
}

export default Swish