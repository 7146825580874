import React from 'react';
import lager from '../../img/lagerutrumme.png'; // Import the image
import pallar2 from '../../img/paller2.png'; // Import the image
import pallar4 from '../../img/pallar4.png';

function Content() {
  return (
    <div className="bg-white">
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex flex-wrap w-full mb-20">
            <div className="lg:w-1/3 w-full mb-6 lg:mb-0">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                Varför sker en likvidation?
              </h1>
              <div className="h-1 w-20 bg-green-500 rounded"></div>
            </div>
            <p className="lg:w-1/3 w-full leading-relaxed text-gray-500">
              Likvidation är som en stor utförsäljning för ett företag. Det
              händer vanligtvis av många anledningar
            </p>
          </div>

          <div className="flex flex-wrap -m-4">
            <div className="xl:w-1/3 md:w-1/3 p-4">
              <div className="bg-gray-100 p-6 rounded-lg">
                <img
                  className="object-cover "
                  alt="lager"
                  src={lager} // Use the imported image here
                />

                <h2 className="text-lg  text-indigo-800 font-medium title-font mb-4">
                  lagerutrymmen
                </h2>
                <p className="leading-relaxed text-base">
                  Ett företag kan ha kostsamma lagerutrymmen och inte
                  tillräckligt med försäljning för att täcka dessa kostnader.
                  Likvidation kan då hjälpa till att minska lagret och spara
                  pengar.
                </p>
              </div>
            </div>

            <div className="xl:w-1/3 md:w-1/3 p-4">
              <div className="bg-gray-100 p-6 rounded-lg">
                <img
                  className="object-cover "
                  alt="lager"
                  src={pallar2} // Use the imported image here
                />
                <h3 className="text-lg  text-indigo-800 font-medium title-font mb-4">
                  Överskottshantering
                </h3>

                <p className="leading-relaxed text-base">
                  Detaljhandlare köper ibland in för mycket i förväntan på hög
                  efterfrågan, vilket leder till överskott som tar upp
                  värdefullt lagerutrymme. Genom att sälja av dessa pallar
                  frigörs utrymme och en del av kostnaderna kan återvinnas
                </p>
              </div>
            </div>

            <div className="xl:w-1/3 md:w-1/3 p-4">
              <div className="bg-gray-100 p-6 rounded-lg">
                <img
                  className="object-cover "
                  alt="lager"
                  src={pallar4} // Use the imported image here
                />
                <h3 className="text-lg  text-indigo-800 font-medium title-font mb-4">
                  Säsongsbunden Lagerrotation
                </h3>

                <p className="leading-relaxed text-base">
                  Säsongsartiklar förlorar snabbt sin relevans efter att deras
                  respektive säsong är över. Genom att sälja av dessa pallar
                  skapas utrymme för ny säsongsvara, vilket håller lagret
                  aktuellt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content;
